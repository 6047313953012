import { http } from 'msw';

import { getEmpUserMenuResolver } from './resolvers/get-emp-user-menu';
import { getSharedAssetListResolver } from './resolvers/global-config/get-shared-asset-list';

export const handlers = [
  http.get('/api/portal/common/menu/user/employee', getEmpUserMenuResolver),
  http.get(
    '/gw/ad/api/system/companygroup/2/asset/shares',
    getSharedAssetListResolver,
  ),
];
