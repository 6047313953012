import { HttpResponse } from 'msw';

const datas = Array.from({ length: 30 }, (_, index: number) => {
  return {
    id: index,
    name: `자산 ${index + 1}`,
    siteName: `사이트 ${index + 1}`,
    companyName: 'daouoffice02 Company',
    companyGroupId: 2,
    shares: Array.from({ length: 3 }, (_, index: number) => {
      return {
        id: index,
        nodeValue: `대상 ${index + 1}`,
        actions: index % 2 == 0 ? 'read,write' : 'read',
      };
    }),
  };
});

export function getSharedAssetListResolver({ request }) {
  const url = new URL(request.url);
  const page = Number(url.searchParams.get('page'));
  const offset = Number(url.searchParams.get('offset'));

  const startIndex = page * offset;
  const endIndex = startIndex + offset;
  const totalPage = Math.ceil(datas.length / offset - 1);
  const hasNextPage = page < totalPage;

  return HttpResponse.json({
    data: datas.slice(startIndex, endIndex),
    hasNext: hasNextPage,
    totalPage,
  });
}
